import * as React from "react"
import Footer from "../../Footer/Footer"
import Header from "../../Header/Header"
import HomePageAbout from "./HomePageAbout/HomePageAbout"
import HomePageBlog from "./HomePageBlog/HomePageBlog"
import HomePageClients from "./HomePageClients/HomePageClients"
import HomePageConnect from "./HomePageConnect/HomePageConnect"
import HomePageIntro from "./HomePageIntro/HomePageIntro"
import HomePageProcess from "./HomePageProcess/HomePageProcess"
import HomePageProjects from "./HomePageProjects/HomePageProjects"
import HomePageReviews from "./HomePageReviews/HomePageReviews"

class HomePage extends React.Component {
  render() {
    return (
      <main className='home-page'>
        <Header/>
        <HomePageIntro/>
        <HomePageAbout/>
        <HomePageProcess/>
        <HomePageProjects/>
        <HomePageReviews/>
        <HomePageClients/>
        <HomePageConnect/>
        <HomePageBlog/>
        <Footer id='contacts'/>
      </main>
    )
  }
}

export default HomePage
