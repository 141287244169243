import cn from 'classnames';
import * as React from 'react';
import { feedbackMessageService } from '../../../../services/FeedbackMessageService';
import Button from '../../../UI/Button/Button';
import Field from '../../../UI/Field/Field';
import Icon from '../../../UI/Icon/Icon';
import { validateEmail } from '../../../../helpers/Utils';

import './HomePageConnect.sass';

class HomePageConnect extends React.Component<{
  id?: string,
}, {
  errors: any[],
  isSuccess: boolean,
  service: string,
}> {

  state = {
    errors          : [],
    submissionError : undefined,
    isSuccess       : false,
    service         : '',
    loading         : false,
  };

  contactForm: any;

  onSubmit = async (e) => {
    e.preventDefault();

    const { email, message } = e.target;
    const { service } = this.state;
    let errors = [];

    if (!email.value || !validateEmail(email.value)) {
      errors.push('email');
    }
    this.setState({ errors });

    if (!!errors.length) return null;

    try {
      this.setState({ loading : true });
      await feedbackMessageService.sendPriceMyDealMessage({
        email       : email.value,
        message     : message.value,
        serviceType : service,
      });
    } catch (err) {
      this.setState({
        submissionError : 'There is a network issue. Please try again later',
        loading         : false,
      });
      return;
    }

    this.setState({
      isSuccess : true,
      loading   : false,
    });

    setTimeout(() => {
      this.setState({ isSuccess : false });
      this.contactForm.reset();
    }, 5000);
  };

  onFocus = (field) => () => {
    const errors = [...this.state.errors].filter(item => item !== field);
    this.setState({ errors, submissionError : undefined });
  };

  handleService = (service: string) => {
    this.setState({ service });
  };

  render() {
    const { errors, isSuccess, service, loading, submissionError } = this.state;

    return (
      <section className='home-page__connect-wrapper'>
        <div className='home-page__connect max-width'>
          <Icon name='heading-connect' className='heading' raw/>
          <div className='connect__left'>
            <Icon name='icon-idea' raw/>
            <h2 className='connect__title'>What can we help you with?</h2>
            <div className='form__field'>
              <label className='form__field-label'>
                What services you need
              </label>
              <div className='field-icon-select'>
                <div
                  className={cn('field-icon-select__card', '-mobile-app', { '-active' : service === 'mobile_app' })}
                  onClick={() => this.handleService('mobile_app')}>
                  <Icon name='icon-mobile-app' raw/>
                  <div className='field-icon-select__name'>Mobile App</div>
                </div>
                <div
                  className={cn('field-icon-select__card', '-solution-architecture', { '-active' : service === 'architecture' })}
                  onClick={() => this.handleService('architecture')}>
                  <Icon name='icon-solution-architecture' raw/>
                  <div className='field-icon-select__name'>Solution Architecture</div>
                </div>
                <div
                  className={cn('field-icon-select__card', '-website-platform', { '-active' : service === 'website' })}
                  onClick={() => this.handleService('website')}>
                  <Icon name='icon-website-platform' raw/>
                  <div className='field-icon-select__name'>Website / Platform</div>
                </div>
              </div>
            </div>
            <form className='connect__contact-form form' name='connect__contact-form'
                  ref={ref => this.contactForm = ref}
                  noValidate
                  onSubmit={this.onSubmit}>
              <Field
                type='email' label='Contact Email' name='email' placeholder='ex. info@mail.com'
                hasError={errors.includes('email') || !!submissionError}
                onFocus={this.onFocus('email')}
                errorMessage={submissionError}
              />
              <Field type='textarea' label='Message' name='message'
                     placeholder='Type your message here...' rows={4}
                     isOptional/>
              {isSuccess ? (
                <div className='footer__contact-success'>
                  <Icon name='icon-bordered-success' raw/>
                  <div>
                    <h4>Perfect!</h4>
                    <div className='footer__contact-success-text'>
                      We have received your message
                    </div>
                  </div>
                </div>
              ) : (
                <Button
                  type='submit' disabled={!!errors.length || loading}
                  className={cn('form__button', { '-disabled' : !!errors.length || loading })}
                >
                  Send Request
                </Button>
              )}
            </form>
          </div>
          <div className='connect__right'>
            <div className='connect__block'>
              <h4 className='connect__info-title'>Marketing & Digital Agencies</h4>
              <div className='connect__info-text'>
                We provide deep technical expertise and work to deliver exceptional user experience
                to your customers.
              </div>
              {/*<div className='connect__info-link' href='/'>READ HOW WE WORK</div>*/}
            </div>
            <div className='connect__block'>
              <h4 className='connect__info-title'>Startups</h4>
              <div className='connect__info-text'>
                We enjoy working with Startups helping them to find a creative way to build and
                launch their products.
              </div>
              {/*<div className='connect__info-link' href=''>READ HOW WE HELP INNOVATE</div>*/}
            </div>
          </div>
        </div>
      </section>
    );
  }

}

export default HomePageConnect;
