import cn from "classnames"
import * as React from "react"

import "./SliderControl.sass"

class SliderControl extends React.Component<{
  direction?: 'next' | 'prev',
  className?: string,
  onClick?: () => void,
}> {

  render() {
    const {direction = 'next', className, onClick} = this.props;
    return (
        <div className={cn('slider-control', className, `-${direction}`)} onClick={onClick}/>
    )
  }
}

export default SliderControl
