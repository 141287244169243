import cn from 'classnames';
import * as React from 'react';

import './AboutAsCard.sass';
import Icon from '../../../../UI/Icon/Icon';

type Props = {
  title: string
  text: string
  icon: 'about-mobile-apps' | 'about-solution' | 'about-websites'
  className?: string
}

const imagePropsByName = {
  'about-mobile-apps' : { width : 86 },
  'about-solution'    : { width : 98 },
  'about-websites'    : { width : 83 },
};

class AboutAsCard extends React.Component<Props> {
  render() {
    const { icon, title, text, className } = this.props;
    return (
      <div className={cn('about-us-card', className)}>
        <div className='about-us-card__icon' style={imagePropsByName[icon]}>
          {/*<img src={`/images/icon-about-${icon}.png`} alt={icon} {...imagePropsByName[icon]}/>*/}
          <Icon name={icon} raw/>
        </div>
        <h5 className='about-us-card__title'>{title}</h5>
        <p className='about-us-card__text'>{text}</p>
      </div>
    );
  }
}

export default AboutAsCard;
