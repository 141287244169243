import * as React from 'react';
import { getCurrentHost } from '../../../../helpers/Utils';
import SequencePlayer from '../../../SequencePlayer/SequencePlayer';
import Socials from '../../../UI/Socials/Socials';

import './HomePageIntro.sass';

const { origin } = new URL(getCurrentHost());
const p = (...paths) => [origin, ...paths].join('/');

const animationInfo = {
  posterUrl   : p('animations/cube/0001.png'),
  sequenceUrl : p('animations/cube/'),
  videoUrl    : p('animations/cube/cube.webm'),
};

class HomePageIntro extends React.Component<{}> {
  render() {
    const { posterUrl, sequenceUrl, videoUrl } = animationInfo;
    return (
      <section className='intro'>
        <div className='max-width'>
          <h2 className='intro__title -desktop'>
            Youshido creates intuitive <span className='underlined'>UX/UI</span>&nbsp;
            <br/>and develops <span className='underlined'>Web & Mobile</span> products&nbsp;
            <br/>elevating digital experience
          </h2>
          <h2 className='intro__title -mobile'>
            Youshido creates intuitive <span className='underlined'>UX/UI</span>&nbsp;
            <br/>and develops <span className='underlined'>Web & Mobile</span> products&nbsp;
            elevating digital experience
          </h2>
          {/*<div className='intro__image'>*/}
          {/*  <SequencePlayer*/}
          {/*    width={1200}*/}
          {/*    height={731}*/}
          {/*    duration={12500}*/}
          {/*    framesCount={300}*/}
          {/*    posterUrl={posterUrl}*/}
          {/*    sequenceUrl={sequenceUrl}*/}
          {/*    videoUrl={videoUrl}*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<Socials/>*/}
        </div>
      </section>
    );
  }
}

export default HomePageIntro;
