import * as React from 'react';
import { PROCESS_STEPS } from '../../../../constants/constants';
import ResolutionContext from '../../../../context/ResolutionContext';
import ProcessSlider from '../../../ProcessSlider/ProcessSlider';

import './HomePageProcess.sass';

class HomePageProcess extends React.Component<{}> {

  renderStep = (step, index) => {
    return [
      !!index ? <div className='home-page__process-step-divider' key={`divider${index}`}/> : '',
      <div className='home-page__process-step' key={index}>
        <span>{index + 1}.</span> {step.title}
      </div>,
    ];
  };

  render() {

    return (
      <section className='home-page__process'>
        <div className='home-page__process-wrapper max-width'>
          <h2 className='home-page__process-title'>
            Our Process
          </h2>
          <ResolutionContext.Consumer>
            {context => context.isMobile ? (
              <ProcessSlider/>
            ) : (
              <>
                <div className='home-page__process-image'>
                  {/*<video width='100%' autoPlay loop playsInline muted>*/}
                  {/*  <source src='/animations/process.mp4'/>*/}
                  {/*</video>*/}
                  <img src='../../../../images/home-page-processes.png'
                       alt='Image: Youshido process'/>
                </div>
                <div className='home-page__process-steps'>
                  {PROCESS_STEPS.map(this.renderStep)}
                </div>
              </>
            )}
          </ResolutionContext.Consumer>
        </div>
      </section>
    );
  }
}

export default HomePageProcess;
