import * as React from 'react';
import BlockHeader from '../../../UI/BlockHeader/BlockHeader';
import Button from '../../../UI/Button/Button';
import { Link } from 'gatsby';
import Icon from '../../../UI/Icon/Icon';

import './HomePageBlog.sass';

const post = {
  title : 'About Traffic Lights and Cultural UX',
  text  : 'There are decisions in Design & UX that are affected by subconscious observations of common things around you...',
  tag   : 'Insights',
  date  : '20 Oct 2020',
  image : 'home-blog.jpg',
};

class HomePageBlog extends React.Component {

  render() {
    return (
      <section className='home-page__blog max-width'>
        <Icon name='heading-blog' className='heading' raw/>
        <div className='home-page__blog-left'>
          <img src={`../../../../images/${post.image}`} className='home-page__blog-post-image'
               alt='Photo: books, cactus and coffee'/>
          <div className='home-page__blog-post-info'>
            <div className='home-page__blog-post-header'>
              {`${post.tag} - ${post.date}`}
            </div>
            <h4 className='home-page__blog-post-title'>{post.title}</h4>
            <div className='home-page__blog-post-description'>{post.text}</div>
          </div>
        </div>
        <div className='home-page__blog-right'>
          <BlockHeader title='What’s the buzz'
                       orientation='vertical'
                       subtitle='Check out our blog for the latest tech trends, innovations, and inspirations.'/>
          <div className='home-page__blog-right-button'>
            <Button style={{ opacity: 0.2 }} disabled>Coming Soon</Button>
          </div>
        </div>
      </section>
    );
  }
}

export default HomePageBlog;
