import { Link } from 'gatsby';
import * as React from 'react';
import { PROJECTS } from '../../../../constants/constants';
import BlockHeader from '../../../UI/BlockHeader/BlockHeader';
import Button from '../../../UI/Button/Button';
import Icon from '../../../UI/Icon/Icon';
import ProjectCard from '../../../UI/ProjectCard/ProjectCard';

import './HomePageProjects.sass';

class HomePageProjects extends React.Component {

  render() {

    return (
      <section className='home-page__projects max-width'>
        <Icon name='heading-works' className='heading' raw />
        <BlockHeader title='Featured Projects'
                     className='home-page__projects-header'
                     subtitle='The real magic happens when clear objectives meet creativity and collaboration meets innovation.'/>
        {PROJECTS.slice(0, 3).map((item, i) => (
          <ProjectCard data={item} key={i}
                       isImageFirst={!!(i % 2)}/>
        ))}
        <Link to='projects' className='home-page__projects-all'>
          <Button>View All Projects</Button>
        </Link>
      </section>
    )
  }
}

export default HomePageProjects
