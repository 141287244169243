import React from 'react'
import PageWrapper from '../components/PageWrapper/PageWrapper'
import HomePage from '../components/Pages/HomePage/HomePage'
import SEO from '../components/seo'
import '../styles/index.sass'

const IndexPage = (props) => (
  <PageWrapper {...props}>
    <SEO title=''/>
    <HomePage/>
  </PageWrapper>
);

export default IndexPage
