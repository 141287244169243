import * as React from 'react';
import List from '../../../List/List';
import BlockHeader from '../../../UI/BlockHeader/BlockHeader';
import Icon from '../../../UI/Icon/Icon';

import './HomePageClients.sass';

const clients = [
  {
    name : 'Pepsi',
    logo : 'client-pepsi',
  },
  {
    name : 'Henkel',
    logo : 'client-henkel',
  },
  {
    name : 'Kinder',
    logo : 'client-kinder',
  },
  {
    name : 'MasterCard',
    logo : 'client-master-card',
  },
  {
    name : 'Cloudkeyz',
    logo : 'client-cloudkeyz',
  },
  {
    name : 'Milka',
    logo : 'client-milks',
  },
  {
    name : 'Durex',
    logo : 'client-durex',
  },
  {
    name : 'Philips',
    logo : 'client-philips',
  },
  {
    name : 'AXE',
    logo : 'client-axe',
  },
  {
    name : 'Viasat',
    logo : 'client-viasat',
  },
  {
    name : 'Vichy',
    logo : 'client-vichy',
  },
  {
    name : 'Saatchi & Saatchi',
    logo : 'client-saatchi',
  },
];

class HomePageClients extends React.Component<{}, {}> {

  render() {

    return (
      <section className='home-page__clients max-width'>
        <BlockHeader title='Happy customers<br/>make us happy'
                     className='home-page__projects-header'
                     subtitle={`We’ve worked with companies all across the globe to communicate what makes their brand unique through unforgettable digital experiences.`}/>
        <Icon name='heading-clients' className='heading' raw/>
        <List data={clients}/>
      </section>
    );
  }
}

export default HomePageClients;
