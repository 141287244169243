import * as React from "react"
import Swiper, { SwiperInstance } from "react-id-swiper"
import { Pagination } from "swiper/js/swiper.esm"
import SliderControl from "../../../SliderControl/SliderControl"
import Icon from "../../../UI/Icon/Icon"
import { ReviewCard } from "../../../UI/ReviewCard/ReviewCard"

import "./HomePageReviews.sass"

const reviews = [
  {
    id          : 0,
    name        : "Gilbert Gonzalez",
    position    : "CEO at Aprise.org",
    avatar      : "gilbert-gonzalez.png",
    companyName : "",
    companyLogo : "",
    message     : "Youshido took an idea that was in my head and walked us through a diligent and effective process for developing an MVP. Biggest selling point was that the work product was some of the best I’ve seen in all my years living and working in Silicon Valley. I highly recommend Youshido. They have a life long client in me.",
  },
  {
    id          : 1,
    name        : "F. Derf",
    position    : "Founder, 1File.com",
    avatar      : "onefile-logo.png",
    companyName : "",
    companyLogo : "",
    message     : <span style={{ fontSize : "0.9em" }}>I had tried a number of dev shops to get this product built, but only Youshido was able to bring to life this high quality and extremely complex product in a simple-to-use, intuitive and elegant app. This is a team of very careful, but detail-obsessed, professionals who invariably get the best implementation of the tasks before them because they are meticulous, imaginative and exhaustive in their efforts.</span>,
  },
  {
    id          : 2,
    name        : "Martin Elkhorn",
    position    : "Founder & CEO, GTEI",
    avatar      : "gte-logo.png",
    companyName : "",
    companyLogo : "",
    message     : <>Innovative. Stylish. Elegant. Timely. <br/>Perfect!</>,
  },
]

const ManipulatingSwiper = (props: any) => {
  const swiperParams: any = {
    slidesPerView  : 1,
    centeredSlides : true,
    loop           : true,
    autoHeight     : true,
    modules        : [Pagination],
    pagination     : {
      el        : ".swiper-pagination",
      type      : "bullets",
      clickable : true,
    },
  }

  return (
    <>
      <Swiper getSwiper={props.setSwiper} {...swiperParams}>
        {reviews.map((data, index) =>
          <ReviewCard data={{ ...data, avatar : "/images/reviewers/" + data.avatar }} key={index}/>,
        )}
      </Swiper>
    </>
  )
}

class HomePageReviews extends React.Component<{}> {

  swiper: SwiperInstance

  setSwiper = (swiper: SwiperInstance) => {
    this.swiper = swiper
    this.setState({})
  }

  render() {
    return (
      <section className='home-page__reviews'>
        <div className='max-width'>
          <Icon name='heading-reviews' className='heading' raw/>
          <div className='home-page__reviews-content'>
            <Icon name='icon-double-quotes' raw/>
            <ManipulatingSwiper setSwiper={this.setSwiper}/>
          </div>
          <div className='home-page__reviews-slider-controls'>
            <SliderControl
              direction='prev'
              className='home-page__reviews-control'
              onClick={() => this.swiper.slidePrev()}
            />
            <SliderControl
              className='home-page__reviews-control'
              onClick={() => this.swiper.slideNext()}
            />
          </div>
        </div>
      </section>
    )
  }
}

export default HomePageReviews
