import * as React from 'react';
import BlockHeader from '../../../UI/BlockHeader/BlockHeader';
import Icon from '../../../UI/Icon/Icon';
import AboutAsCard from './AboutUsCard/AboutAsCard';

import './HomePageAbout.sass';

class HomePageAbout extends React.Component {

  render() {
    return (
      <section className='home-page__about'>
        <div className='max-width'>
          <Icon name='heading-about' className='home-page__about-heading' raw/>
          <BlockHeader
            className='home-page__about-header'
            title='What drives us?<br/><span class="home-page__about-subtitle">Sparking joy and excitement in<br/> user experiences!</span>'
            subtitle='We help our clients uncover what their customers truly crave from their app or website — then we turn it into reality. From web and app design to MVPs, augmented reality, hardware prototypes, and firmware, we passionately develop your customer touch points to optimize interactions and ensure you put your best foot forward.'
          />
          <div className='home-page__about-cards'>
            <AboutAsCard
              title='Websites / Web Apps'
              className='home-page__about-card'
              text='We create stylish and effective web products based on business research and CA needs — from landings to complicated systems.'
              icon='about-websites'
            />
            <AboutAsCard
              title='Mobile Applications'
              className='home-page__about-card'
              text='We inject your brand’s distinct personality into mobile app experiences. When consumers enjoy using your app, engagement will skyrocket.'
              icon='about-mobile-apps'
            />
            <AboutAsCard
              title='Solution Architecture'
              className='home-page__about-card'
              text='Our Solution Architecture bridges the gap between business needs and technology by using an efficient and reliable technology stack designed for your company.'
              icon='about-solution'
            />
          </div>
        </div>
      </section>
    );
  }
}

export default HomePageAbout;
